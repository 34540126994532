import React from "react";
import { Container, Row, Col } from 'react-bootstrap';

import "./subHeaderNav.scss";

const SubNavHeader = ({navMenu}) => {
    return (
        <Container fluid className="footer common-quote-button">
            <div className="nav-section">
                <Row>
                    <Col sm={12} md={6}>
                        <h4>{navMenu}</h4>
                    </Col>
                    <Col sm={12} md={6} className="ms-auto navigation">
                        <p className="mb-0">
                            <span>Home</span>&nbsp;&nbsp;/&nbsp;&nbsp;{navMenu}
                        </p>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default SubNavHeader

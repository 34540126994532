import React from "react";
import { Card } from "react-bootstrap";

import "./servicesCard.scss";

const ServicesCard = ({data}) => {

    return (
        <div className="single-card">
            <Card>
                <Card.Body>
                    {data.icon}
                    <Card.Title>{data.title}</Card.Title>
                    <Card.Text>{data.description}</Card.Text>
                </Card.Body>
            </Card>
        </div>
    )
}

export default ServicesCard

import React from "react";

import './getAQuote.scss'
import { Button, Col, Container, Row } from "react-bootstrap";

const GetAQuote = () => {
    return (
        <div className="get-quote-section common-quote-button">
             <Container fluid>
                <Row>
                    <Col xs={12} md={8} >
                        <h4 className="text-center text-bold">Secure Your Estimate</h4>
                        <p className="text-center">
                            With years of expertise, Atrilon delivers top-notch engineering solutions tailored to your needs. Our dedicated team ensures every project is executed with precision and excellence. Ready to elevate your project? Request a quote today and see how we can bring your vision to life.
                        </p>
                    </Col>
                    <Col xs={12} md={4} className="quote">
                        <Button href="/contact" className="btn-quote">Request Quote</Button>
                    </Col>
                </Row>
           </Container>
        </div>
    )
}

export default GetAQuote

import { Carousel } from 'react-bootstrap';
import "./carousel.scss"

// Carousel images
import CarouselImage1 from '../../Assets/Carousel-1.jpg';
import CarouselImage2 from '../../Assets/Carousel-2.jpg';
import CarouselImage3 from '../../Assets/Carousel-3.jpeg';

const MainCarousel = () => {
    return (
        <Carousel className="carousel-height">
            <Carousel.Item interval={15000}>
                <img className="d-block w-100 carousel-img" src={CarouselImage1} alt="First slide"/>
                <Carousel.Caption className="carousel-caption">
                    <h5>Engineering Excellence: Transforming Ideas into Reality</h5>
                    <p>
                        Welcome to Atrilon Technologies, your trusted partner in engineering excellence. We specialize in transforming innovative ideas into reality with precision and performance. Our cutting-edge engineering services are designed to build tomorrow, today, driving innovation and delivering perfection in every project. Let us engineer the future together, ensuring every solution meets the highest standards of quality and excellence.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img className="d-block w-100 carousel-img" src={CarouselImage2} alt="Second slide"
                />
                <Carousel.Caption className="carousel-caption">
                    <h5>Engineering Excellence: Transforming Ideas into Reality</h5>
                    <p>
                        Welcome to Atrilon Technologies, your trusted partner in engineering excellence. We specialize in transforming innovative ideas into reality with precision and performance. Our cutting-edge engineering services are designed to build tomorrow, today, driving innovation and delivering perfection in every project. Let us engineer the future together, ensuring every solution meets the highest standards of quality and excellence.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>

            <Carousel.Item>
                <img className="d-block w-100 carousel-img" src={CarouselImage3} alt="Third slide"
                />
                <Carousel.Caption className="carousel-caption">
                    <h5>Engineering Excellence: Transforming Ideas into Reality</h5>
                    <p>
                        Welcome to Atrilon Technologies, your trusted partner in engineering excellence. We specialize in transforming innovative ideas into reality with precision and performance. Our cutting-edge engineering services are designed to build tomorrow, today, driving innovation and delivering perfection in every project. Let us engineer the future together, ensuring every solution meets the highest standards of quality and excellence.
                    </p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default MainCarousel;
import React, { Fragment } from "react"

import SubHeaderNav from '../../CommonComponents/SunHeaderNav/subHeaderNav'
import ServicesMenu from '../../CommonComponents/ServicesMenuSection/servicesMenu'

import './services.scss'

const Services = () => {
    return (
        <Fragment>  
            <SubHeaderNav navMenu="Services" />
            <ServicesMenu />         
        </Fragment>
    )
}

export default Services

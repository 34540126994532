import React from "react";
import { Container, Row, Col } from 'react-bootstrap';
import { HiOutlineLightBulb } from "react-icons/hi";
import { FaUsersViewfinder } from "react-icons/fa6";
import { GrTechnology } from "react-icons/gr";
import { FaHandshake } from "react-icons/fa";

import AboutImage from '../../Assets/about-us.jpeg'

import "./aboutSection.scss";

const AboutSection = () => {
    return (
        <Container className="common-quote-button mt-5 mb-5">
            <div className="about-section">
                <Row>
                    <Col  sm={12} md={5}>
                        <img className="d-block w-100" src={AboutImage} alt="First slide"/>
                    </Col>
                    <Col sm={12} md={7} className="p-3">
                        <h3 className="text-bold mb-3">Innovations Beyond Boundaries</h3>
                        <p className="mb-5">At Atrilon Technologies, we push the limits of engineering with groundbreaking innovations. Our cutting-edge solutions transcend boundaries, setting new standards for the industry.</p>
                        <Row className="mb-4">
                            <Col className="about-menu" xs={12}>
                                <Row>
                                    <Col sm={2} md={2}>
                                        <HiOutlineLightBulb className="menu-icon" />
                                    </Col>
                                    <Col sm={10} md={10}>
                                        <h5 className="text-bold mb-2">Engineering Excellence and Innovation</h5>
                                        <p>Founded on a commitment to engineering excellence, We are specializes in rotor dynamics, bearing analysis, and advanced simulations. We optimize machinery performance and efficiency with tailored solutions, blending expertise and cutting-edge technology to meet diverse client needs</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="about-menu" xs={12}>
                                <Row>
                                    <Col sm={2} md={2}>
                                        <FaUsersViewfinder className="menu-icon" />
                                    </Col>
                                    <Col sm={10} md={10}>
                                        <h5 className="text-bold mb-2">Customer-Centric Approach</h5>
                                        <p>At Atrilon Technologies, we prioritize customer-centricity and collaborative partnerships, tackling complex issues and designing robust strategies. Our holistic solutions ensure sustainable growth and operational success by addressing immediate needs and future-proofing operations</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="about-menu" xs={12}>
                                <Row>
                                    <Col sm={2} md={2}>
                                        <GrTechnology className="menu-icon" />
                                    </Col>
                                    <Col sm={10} md={10}>
                                        <h5 className="text-bold mb-2">Leading Technological Advancements</h5>
                                        <p>Atrilon Technologies pioneers research and development in mechanical engineering, staying at the forefront of technological advancements. We invest in people and resources to deliver innovative solutions that optimize efficiency and reliability, ensuring lasting success for our partners</p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="about-menu" xs={12}>
                                <Row>
                                    <Col sm={2} md={2}>
                                        <FaHandshake className="menu-icon" />
                                    </Col>
                                    <Col sm={10} md={10}>
                                        <h5 className="text-bold mb-2">Partner with Us</h5>
                                        <p className="justify">Experience precision and innovation at Atrilon Technologies Pvt Ltd. Contact us today to elevate your machinery performance and exceed business objectives together</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}

export default AboutSection

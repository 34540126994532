import React, { useState } from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import './servicesMenu.scss';

// Icons
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaCogs } from "react-icons/fa";
import { BiAperture } from "react-icons/bi";
import { TbBrandPython } from "react-icons/tb";
import { TbActivity } from "react-icons/tb";


// Images
import Service1 from '../../Assets/Services/product-design-and-development.jpeg';
import Service2 from '../../Assets/Services/Engineering-analysis.jpg';
import Service3 from '../../Assets/Services/Rotor-dynamics-and-bearing-analysis.gif';
import Service4 from '../../Assets/Services/Prototyping-and-testing.jpeg';
import Service5 from '../../Assets/Services/Customized-web-applications.jpeg';
import Service6 from '../../Assets/Services/Training-and-technical-support.jpeg';

const services = [
  {
    title: 'Product Design and Development',
    icon: <TbActivity />,
    image: Service1,
    content: `Product design and development is a multidisciplinary process that involves conceptualizing, designing, and bringing a new product to market. It starts with identifying customer needs and market opportunities, followed by ideation and sketching. The process includes detailed design, prototyping, and testing to refine the product's functionality, aesthetics, and user experience. Engineering and manufacturing teams then collaborate to ensure the product is feasible to produce at scale, maintaining quality and cost-effectiveness. Throughout the lifecycle, feedback loops and iterative improvements help in creating a final product that meets customer expectations and stands out in the market.`
  },
  {
    title: 'Engineering Analysis',
    icon: <FaCogs />,
    image: Service2,
    content: `Unlock precision engineering insights with our advanced Finite Element Analysis (FEA) capabilities. Utilizing cutting-edge simulation tools, we ensure robust optimization and validation for your engineering designs. Below is our expertise:, Static linear/non-linear analysis, Eigen value analysis – Modal, Frequency response/Harmonic analysis, Random vibration analysis, Shock/Transient analysis, Response spectrum analysis, Thermo-mechanical analysis, Explicit analysis, Rigid dynamics, Topology optimization , Fatigue calculations, Welding simulations, Buckling analysis, Industries – Turbomachinery, heavy industry, aerospace, automotive, space, gear technologies, marine, defence, electronic appliances
    `
  },
  {
    title: 'Rotor Dyanamics and Bearing Analysis',
    icon: <BiAperture />,
    image: Service3,
    content: `At Atrilon Technologies Pvt Ltd, we specialize in offering comprehensive rotor dynamics and bearing analysis services tailored to meet the precise needs of our clients. With a deep understanding of mechanical systems and advanced analytical tools, our expert team ensures robust support for optimizing rotor performance and bearing functionality. From initial diagnostics to detailed simulations and ongoing maintenance strategies, we are committed to enhancing operational efficiency, reliability, and longevity of critical rotating machinery across various industries. Trust Atrilon Technologies for cutting-edge solutions that safeguard your equipment's performance and productivity. Below is our expertise:, Lateral analysis, Torsional analysis, Unbalance analysis, Steady state analysis, Frequency/time domain analysis, Whirl speed & stability analysis, Bearing design & analysis, Pressure profile, Minimum film thickness/pressure, Stiffness and damping, Frictional loss
    `
  },
  {
    title: 'Prototyping and Testing',
    icon: <AiOutlineSafetyCertificate />,
    image: Service4,
    content: `Prototyping is an essential phase in product development where a preliminary model or sample of a product is created. This prototype serves as a tangible representation of the concept, allowing designers and engineers to explore ideas, test functionalities, and refine designs. It can range from simple sketches or digital models to fully functional versions of the product, depending on the stage of development and the requirements.
    Testing follows prototyping and involves evaluating the prototype's performance, usability, and durability. This process aims to identify any flaws, inconsistencies, or areas for improvement. Through rigorous testing, feedback is gathered to make necessary adjustments, ensuring the final product meets the desired standards and user expectations. Together, prototyping and testing help reduce risks, save costs, and enhance the overall quality and feasibility of the product before it reaches the market.
    `
  },
  {
    title: 'Customized Web Apps for Calculations',
    icon: <TbBrandPython />,
    image: Service5,
    content: `Customized web calculators in mechanical design and analysis are specialized online tools tailored to specific engineering needs.
    These calculators facilitate complex computations and simulations, helping engineers design and analyze mechanical systems with precision.
    They can perform a wide range of functions, from stress and strain analysis to thermal performance and fluid dynamics calculations. By incorporating user-defined parameters and industry-specific standards, these calculators enhance efficiency, reduce errors, and enable more accurate and optimized designs.
     Accessible from any web browser, they are valuable resources for professionals seeking to streamline their workflows and improve productivity in mechanical engineering projects.`
  },
  {
    title: 'Training and Technical Support',
    icon: <RiCustomerService2Fill />,
    image: Service6,
    content: `Training and technical support are essential services that ensure users can effectively utilize and maintain technology products and solutions. 
    Training involves structured programs designed to educate users about the functionalities, features, and best practices of a specific technology. This can include hands-on workshops, online courses, webinars, and detailed documentation, aimed at improving user proficiency and confidence.
    Technical Support provides users with assistance to resolve any issues or challenges they encounter while using a technology product. This support can be delivered through various channels such as help desks, live chats, phone support, and remote assistance, ensuring that users can get timely and effective solutions to their problems. 
    Together, these services enhance user experience, maximize productivity, and ensure smooth and efficient operation of technology solutions.
    `
  },
];

const ServicesMenu = () => {
  const [activeService, setActiveService] = useState(services[0]);

  return (
    <Container className="common-quote-button mt-5 mb-5 service-section">
      <Row className='text-center mb-4'>
        <Col>
          <h3 className="text-bold mb-3">Engineering Excellence and Beyond</h3>
          <p className="mb-4">
          Innovating tailored solutions to enhance performance and efficiency across industries, exceeding expectations with every project.
          </p>
        </Col>
      </Row>
      <Row>
        <Col md={4} className="service-menu">
          <Nav variant="pills" className="flex-column">
            {services.map((service, index) => (
              <Nav.Link
                  key={index}
                  onClick={() => setActiveService(service)}
                  active={activeService.title === service.title}
                  className="mb-3"
                >
                  <div className="services-menu">
                    <span className="">{service.icon}</span>
                    <h6>{service.title}</h6>
                  </div>
                </Nav.Link>
            ))}
          </Nav>
        </Col>
        <Col md={8} className="service-content">
          <img className="service-image float-start me-4 mb-3" src={activeService.image} alt={activeService.title} />
          <p>{activeService.content}</p>
        </Col>
      </Row>
    </Container>
  );
};

export default ServicesMenu;

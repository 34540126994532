import React, { Fragment } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Header from './CommonComponents/Header/header'
import Footer from './CommonComponents/Footer/footer'
import Home from './Pages/Home/home'
import About from './Pages/About/aboutUs'
import Services from './Pages/Services/services'
import Contact from './Pages/Contact/contactUs'
import Career from './Pages/Career/career'
import Blogs from './Pages/Blogs/blogs'

const App = () => {
    return (
        <Fragment>
            <BrowserRouter>
                <Header />
                <Routes>
                    <Route path="/" element={ <Home/> } />
                    <Route path="/about" element={ <About/> } />
                    <Route path="/services" element={ <Services/> } />
                    <Route path="/career" element={ <Career/> } />
                    <Route path="/blogs" element={ <Blogs/> } />
                    <Route path="/contact" element={ <Contact/> } />
                </Routes>
                <Footer />
            </BrowserRouter>
        </Fragment>
    )
}

export default App
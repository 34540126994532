import React, { Fragment } from "react"

import MainCarousel from '../../CommonComponents/Carousel/carousel';
import GetAQuote from '../../CommonComponents/GetAQuoteSection/getAQuote';
import ServicesCardSection from '../../CommonComponents/ServicesCardSection/servicesCardSection';

const Home = () => {
    return (
        <Fragment>
            <MainCarousel />
            <GetAQuote />
            <ServicesCardSection />
        </Fragment>
    )
}

export default Home

import React, { Fragment } from "react"

import ComingSoon from '../../CommonComponents/CommingSoon/comingSoon'

const Career = () => {
    return (
        <Fragment>
            <ComingSoon content="Prepare to embark on a new journey. Your career destination awaits!"  />
        </Fragment>
    )
}

export default Career

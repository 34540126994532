import React, { Fragment } from "react"

import SubHeaderNav from '../../CommonComponents/SunHeaderNav/subHeaderNav'
import AboutSection from '../../CommonComponents/AboutSection/aboutSection'

const About = () => {
    return (
        <Fragment>
            <SubHeaderNav navMenu="About" />
            <AboutSection />
        </Fragment>
    )
}

export default About

import React, { Fragment } from "react"

import SubHeaderNav from '../../CommonComponents/SunHeaderNav/subHeaderNav'
import GoogleMapComponent from "../../CommonComponents/GoogleMapLocation/googleMapLocation"
// import ContactForm from '../../CommonComponents/ContactForm/contactForm'

const Contact = () => {
    return (
        <Fragment>
            <SubHeaderNav navMenu="Contact" />
            <GoogleMapComponent />
            {/* <ContactForm /> */}
        </Fragment>
    )
}

export default Contact

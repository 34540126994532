import React from 'react';
import { Container, Row, Col } from "react-bootstrap";

//Icons
import { IoLocationOutline } from "react-icons/io5";
import { TfiEmail } from "react-icons/tfi";
import { HiOutlineDevicePhoneMobile } from "react-icons/hi2";

import './googleMapLocation.scss'

const GoogleMapComponent = () => {
  return (
    <div>
      <div fluid className="google-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.9789282229895!2d77.6352141!3d12.9090758!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1493c1199dbb%3A0xe21163f1a7f5c9b0!2sSierra%20Cartel!5e0!3m2!1sen!2sin!4v1720952759089!5m2!1sen!2sin"
          width="600"
          title="Location"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        >
        </iframe>
      </div>
      <Container className="address">
        <Row className="justify-content-center mt-5 mb-5">
          <Col md={10}>
            <div className="address-wrapper">
              <Row>
                <Col sm={12} md={4} className='address-col'>
                  <div className="address-card">
                    <div className='address-icon'>
                      <IoLocationOutline />
                    </div>
                    <div>
                      <h4>Location:</h4>
                      <p>
                        1207/343 & 1207/1/343/1, <br />
                        9th Main, 7th Sector, <br />
                        HSR Layout, Bangalore, <br />
                        KA 560102 IN
                      </p>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={4} className='address-col'>
                  <div className="address-card">
                    <div className='address-icon'>
                      <TfiEmail />
                    </div>
                    <div>
                      <h4>Email:</h4>
                      <p><a href="mailto:info@atrilon.com">info@atrilon.com</a></p>
                    </div>
                  </div>
                </Col>
                <Col sm={12} md={4} className='address-col'>
                  <div className="address-card">
                    <div className='address-icon'>
                      <HiOutlineDevicePhoneMobile />
                    </div>
                    <div>
                      <h4>Call:</h4>
                      <p>+91 70226 77358</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>

          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GoogleMapComponent;

import React from "react";
import { Container, Row,  Col } from "react-bootstrap";
import ServicesCard from "./servicesCard";
import "./servicesCard.scss";

// Icons
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { RiCustomerService2Fill } from "react-icons/ri";
import { FaCogs } from "react-icons/fa";
import { BiAperture } from "react-icons/bi";
import { TbBrandPython } from "react-icons/tb";
import { TbActivity } from "react-icons/tb";

const servicesCardDetails = [
    {
        title: "Product Design and Development",
        icon: <TbActivity  className="card-icon" />,
        description: "Transforming ideas into innovative, market-ready products through expert design and development"
    },
    {
        title: "Engineering Analysis",
        icon: <FaCogs  className="card-icon" />,
        description: "Leverage advanced FE analysis for precise engineering solutions and optimized designs"
    },
    {
        title: "Rotor Dyanamics and Bearing Analysis",
        icon: <BiAperture className="card-icon" />,
        description: "Specializing in rotor dynamics and precision bearings, enhancing machinery efficiency and reliability through expert solutions"
    },
    {
        title: "Prototyping and Testing",
        icon: <AiOutlineSafetyCertificate  className="card-icon" />,
        description: "Transform your ideas into robust prototypes with our expert development. Ensure flawless performance through rigorous testing, tailored to your needs"
    },
    {
        title: "Customized Web Apps for Calculations",
        icon: <TbBrandPython className="card-icon" />,
        description: "Discover our specialized web-based mechanical calculators, designed to streamline precise engineering analysis and optimize design solutions for industry applications"
    },
    {
        title: "Training and Technical Support",
        icon:  <RiCustomerService2Fill  className="card-icon" />,
        description: "Providing comprehensive training and technical support solutions to empower seamless implementation and continuous proficiency in utilizing our products and services"
    }
]

const ServicesCardSection = () => {
    return (
        <div>
            <Container className="service-card-section">
                <Row className="service-card-section-row row-1">
                    <Col sm={12} md={4}>
                        <ServicesCard data={servicesCardDetails[0]} />
                    </Col>
                    <Col sm={12} md={4}>
                        <ServicesCard data={servicesCardDetails[1]} />
                    </Col>
                    <Col sm={12} md={4}>
                        <ServicesCard data={servicesCardDetails[2]} />
                    </Col>
                </Row>
                <Row className="service-card-section-row row-2">
                    <Col sm={12} md={4}>
                        <ServicesCard data={servicesCardDetails[3]} />
                    </Col>
                    <Col sm={12} md={4}>
                        <ServicesCard data={servicesCardDetails[4]} />
                    </Col>
                    <Col sm={12} md={4}>
                        <ServicesCard data={servicesCardDetails[5]} />
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ServicesCardSection

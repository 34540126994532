import React, { Fragment } from "react"

import ComingSoon from '../../CommonComponents/CommingSoon/comingSoon'

const Blogs = () => {
    return (
        <Fragment>
            <ComingSoon content="Unlock inspiration. Discover stories that ignite your curiosity. Stay tuned! " />
        </Fragment>
    )
}

export default Blogs

import React from 'react';
import { Container } from 'react-bootstrap';
import './comingSoon.scss';

const ComingSoon = ({content}) => {
  return (
    <Container fluid className="coming-soon-container">
      <h3 className="text-bold">Coming Soon!</h3>
      <p>{content}</p> 
    </Container>
  );
};

export default ComingSoon;
